import { useLocation, withRouter } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import queries from "../helpers/queries";
import moment from "moment";
import { FaArrowRight } from "react-icons/fa";
import { useState } from "react";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import { useForm } from "react-hook-form";
import axios from "axios";

const NumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const renderItem = (name, paxValue, itemValue) => {
    if (parseInt(paxValue) !== 0) {
        return (
            <div className="flex justify-between mb-2">
                <div className="">
                    {paxValue} x {name}:
                </div>
                <div>RM {NumberFormatter.format(paxValue * itemValue)}</div>
            </div>
        );
    }
    return null;
};

const Checkout = withRouter(() => {
    const locate = useLocation();
    const apollo = useApolloClient();

    const passedData = locate.state.payload;

    const [createOrder] = useMutation(queries.CREATE_ORDER);

    const [airlinesData, setAirlinesData] = useState([]);

    const { data } = useQuery(queries.FETCH_AIRLINE, {
        variables: {},
        fetchPolicy: "network-only",
        onCompleted: () => setAirlinesData(data.fetchAirlines),
    });

    const generateSignature = async () => {
        const ikSigReq = await apollo.query({
            query: queries.GENERATE_IK_SIGNATURE,
            fetchPolicy: "no-cache",
        });

        return ikSigReq;
    };

    const handleCreate = async () => {
        try {
            const formValues = getValues();

            let updatedImage = undefined;

            if (formValues.image.length > 0) {
                const imageresult = await generateSignature();

                const updateImageFormData = new FormData();

                updateImageFormData.append("file", formValues.image[0]);
                updateImageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                // ik signature expire token
                updateImageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                updateImageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                updateImageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                updateImageFormData.append(
                    "fileName",
                    `${formValues.image[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                updateImageFormData.append("folder", "packages");

                const updateImageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    updateImageFormData
                );

                updatedImage = updateImageUploadResult.data.url;
            }

            const result = await createOrder({
                variables: {
                    _id: passedData._id,
                    members: passedData.members,
                    slots: passedData.slots,
                    tourDate: passedData.tourDate,
                    tour: passedData.tour,
                    price: passedData.price,
                    childPax: passedData.childPax,
                    adultPax: passedData.adultPax,
                    childnobedPax: passedData.childnobedPax,
                    singlePax: passedData.singlePax,
                    infantPax: passedData.infantPax,
                    receipt: updatedImage,
                    phone: formValues.phone,
                    email: formValues.email,
                },
            });

            if (result.data) {
                alert("Your order has been created successfully. We will contact you shortly. Thank you for your support with longrich");
                window.location.href = "/";
            }
        } catch (error) {
            console.error("Error creating order:", error);
            alert(
                "Failed to create order. Please check if there are enough slots available."
            );
        }
    };

    const { register, errors, handleSubmit, getValues } = useForm({
        defaultValues: {
            image: "",
            email: "",
            phone: ""
        },
    });

    const departDates = locate.state.payload.departDates;
    const arrivalDates = locate.state.payload.arrivalDates;

    return (
        <div>
            <div className=" tnc-background">
            <div className="greyOpaqueBackground px-8 py-6">
                        <div className="my-4 text-3xl font-bold text-center ">
                            ORDER SUMMARY
                        </div>
                        </div>
                {departDates
                    ? departDates.map((item, i) => {
                          const selectedAirline = airlinesData.find(
                              (airline) => airline.name === item.airway
                          );
                          const duration = item.endTime - item.startTime;
                          const hours = Math.floor(duration / (1000 * 60 * 60));
                          const minutes = Math.floor(
                              (duration / (1000 * 60)) % 60
                          );

                          const transitduration =
                              item.transitEndTime - item.transitStartTime;
                          const transithours = Math.floor(
                              transitduration / (1000 * 60 * 60)
                          );
                          const transitminutes = Math.floor(
                              (transitduration / (1000 * 60)) % 60
                          );
                          return (
                              <div
                                  className="bg-white shadow-md  px-8 py-6 max-w-4xl mx-auto grid grid-cols-1 mt-6"
                                  key={i}
                              >
                                  <div className="block ">
                                      <div className="grid lg:grid-cols-1">
                                          <div
                                              style={{ width: "200px" }}
                                              className="flex justify-center border-solid bg-white flex-col col-span-1"
                                          >
                                              <div className="">
                                                  <div className="mb-4 text-xl font-bold text-gray-500">
                                                      DEPARTURES
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="grid lg:grid-cols-1">
                                          <div className="flex justify-center bg-white flex-col col-span-1">
                                              <div className="w-full">
                                                  <div className="flex flex-col p-8">
                                                      <div className="flex flex-col lg:flex-col">
                                                          <div className="my-1 text-sm flex items-center capitalize">
                                                              {selectedAirline && (
                                                                  <img
                                                                      src={
                                                                          selectedAirline
                                                                              ? selectedAirline.img
                                                                              : ""
                                                                      }
                                                                      alt={
                                                                          selectedAirline
                                                                              ? selectedAirline.name
                                                                              : ""
                                                                      }
                                                                      className="ml-2"
                                                                      style={{
                                                                          maxWidth:
                                                                              "150px",
                                                                          maxHeight:
                                                                              "150px",
                                                                      }}
                                                                  />
                                                              )}
                                                              <div className="ml-4 font-bold">
                                                                  {selectedAirline
                                                                      ? selectedAirline.name
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <hr className="my-3"></hr>
                                                      <div className="flex items-center mb-4">
                                                          <strong className="mr-2">
                                                              {item.startCity}
                                                          </strong>
                                                          <span className="mr-2">
                                                              <FaArrowRight />
                                                          </span>
                                                          <strong>
                                                              {item.endCity}
                                                          </strong>
                                                      </div>

                                                      <div className="mb-4">
                                                          {item.code} |{" "}
                                                          {moment(
                                                              parseInt(
                                                                  item.startTime
                                                              )
                                                          ).format(
                                                              "HH:mm"
                                                          )}{" "}
                                                          -{" "}
                                                          {moment(
                                                              parseInt(
                                                                  item.endTime
                                                              )
                                                          ).format(
                                                              "HH:mm"
                                                          )}{" "}
                                                          | {hours} hours{" "}
                                                          {minutes} minutes
                                                      </div>

                                                      <hr />
                                                  </div>
                                              </div>
                                          </div>
                                          {item.transitStartTime && (
                                              <div className="w-full border border-black tnc-background">
                                                  <div className="flex flex-col p-8 greyOpaqueBackground">
                                                      <div className="my-1 text-white text-xl capitalize">
                                                          <strong>
                                                              TRANSIT
                                                          </strong>{" "}
                                                      </div>
                                                      <div className="flex items-center mb-4">
                                                          <strong className="mr-2">
                                                              {
                                                                  item.transitStartCity
                                                              }
                                                          </strong>
                                                          <span className="mr-2">
                                                              <FaArrowRight />
                                                          </span>
                                                          <strong>
                                                              {
                                                                  item.transitEndCity
                                                              }
                                                          </strong>
                                                      </div>
                                                      <div className="mb-4">
                                                          {item.code} |{" "}
                                                          {moment(
                                                              parseInt(
                                                                  item.transitStartTime
                                                              )
                                                          ).format(
                                                              "HH:mm"
                                                          )}{" "}
                                                          -{" "}
                                                          {moment(
                                                              parseInt(
                                                                  item.transitEndTime
                                                              )
                                                          ).format(
                                                              "HH:mm"
                                                          )}{" "}
                                                          | {transithours} hours{" "}
                                                          {transitminutes}{" "}
                                                          minutes
                                                      </div>
                                                      <hr />
                                                  </div>
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : ""}
                {arrivalDates
                    ? arrivalDates.map((item, i) => {
                          const selectedAirline = airlinesData.find(
                              (airline) => airline.name === item.airway
                          );
                          const duration = item.endTime - item.startTime;
                          const hours = Math.floor(duration / (1000 * 60 * 60));
                          const minutes = Math.floor(
                              (duration / (1000 * 60)) % 60
                          );

                          const transitduration =
                              item.transitEndTime - item.transitStartTime;
                          const transithours = Math.floor(
                              transitduration / (1000 * 60 * 60)
                          );
                          const transitminutes = Math.floor(
                              (transitduration / (1000 * 60)) % 60
                          );
                          return (
                              <div className="bg-white px-8 py-6 max-w-4xl mx-auto grid grid-cols-1 gap-4 mb-12">
                                  <div className="block ">
                                      <div className="grid lg:grid-cols-1">
                                          <div
                                              style={{ width: "200px" }}
                                              className="flex justify-center border-solid bg-white flex-col col-span-1"
                                          >
                                              <div className="">
                                                  <div className="mb-4 text-xl font-bold text-gray-500">
                                                      ARRIVALS
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="grid lg:grid-cols-1">
                                          <div className="flex justify-center bg-white flex-col col-span-1">
                                              <div className="w-full">
                                                  <div className="flex flex-col p-8">
                                                      <div className="flex flex-col lg:flex-col">
                                                          <div className="my-1 text-sm flex items-center capitalize">
                                                              {selectedAirline && (
                                                                  <img
                                                                      src={
                                                                          selectedAirline
                                                                              ? selectedAirline.img
                                                                              : ""
                                                                      }
                                                                      alt={
                                                                          selectedAirline
                                                                              ? selectedAirline.name
                                                                              : ""
                                                                      }
                                                                      className="ml-2"
                                                                      style={{
                                                                          maxWidth:
                                                                              "150px",
                                                                          maxHeight:
                                                                              "150px",
                                                                      }}
                                                                  />
                                                              )}
                                                              <div className="ml-4 font-bold">
                                                                  {selectedAirline
                                                                      ? selectedAirline.name
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <hr className="my-3"></hr>
                                                      <div className="flex items-center mb-4">
                                                          <strong className="mr-2">
                                                              {item.startCity}
                                                          </strong>
                                                          <span className="mr-2">
                                                              <FaArrowRight />
                                                          </span>
                                                          <strong>
                                                              {item.endCity}
                                                          </strong>
                                                      </div>

                                                      <div className="mb-4">
                                                          {item.code} |{" "}
                                                          {moment(
                                                              parseInt(
                                                                  item.startTime
                                                              )
                                                          ).format(
                                                              "HH:mm"
                                                          )}{" "}
                                                          -{" "}
                                                          {moment(
                                                              parseInt(
                                                                  item.endTime
                                                              )
                                                          ).format(
                                                              "HH:mm"
                                                          )}{" "}
                                                          | {hours} hours{" "}
                                                          {minutes} minutes
                                                      </div>

                                                      <hr />
                                                  </div>
                                              </div>
                                          </div>
                                          {item.transitStartTime && (
                                              <div className="w-full border border-black tnc-background">
                                                  <div className="flex flex-col p-8 greyOpaqueBackground">
                                                      <div className="my-1 text-white text-xl capitalize">
                                                          <strong>
                                                              TRANSIT
                                                          </strong>{" "}
                                                      </div>
                                                      <div className="flex items-center mb-4">
                                                          <strong className="mr-2">
                                                              {
                                                                  item.transitStartCity
                                                              }
                                                          </strong>
                                                          <span className="mr-2">
                                                              <FaArrowRight />
                                                          </span>
                                                          <strong>
                                                              {
                                                                  item.transitEndCity
                                                              }
                                                          </strong>
                                                      </div>
                                                      <div className="mb-4">
                                                          {item.code} |{" "}
                                                          {moment(
                                                              parseInt(
                                                                  item.transitStartTime
                                                              )
                                                          ).format(
                                                              "HH:mm"
                                                          )}{" "}
                                                          -{" "}
                                                          {moment(
                                                              parseInt(
                                                                  item.transitEndTime
                                                              )
                                                          ).format(
                                                              "HH:mm"
                                                          )}{" "}
                                                          | {transithours} hours{" "}
                                                          {transitminutes}{" "}
                                                          minutes
                                                      </div>
                                                      <hr />
                                                  </div>
                                              </div>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : ""}

                {locate.state.payload.members &&
                    locate.state.payload.members.map((item, i) => {
                        if (item.designation) {
                            return (
                                <div
                                    className="bg-white shadow-md rounded-lg mb-6 px-8 py-6 max-w-4xl mx-auto"
                                    key={i}
                                >
                                    <div className="mb-4 text-xl font-bold text-gray-500">
                                        PASSENGER DETAILS
                                    </div>
                                    <div className="mb-6">
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Designation:
                                            </div>
                                            <div>{item.designation}</div>
                                        </div>
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Passenger Name:
                                            </div>
                                            <div>{item.name}</div>
                                        </div>
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Date of Birth:
                                            </div>
                                            <div>{item.dob}</div>
                                        </div>
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Passport:
                                            </div>
                                            <div>{item.passport}</div>
                                        </div>
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Phone:
                                            </div>
                                            <div>{item.phone}</div>
                                        </div>
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Remark:
                                            </div>
                                            <div className="long-text">
                                                {item.remark}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    className="bg-white shadow-md rounded-lg mb-6 px-8 py-6 max-w-4xl mx-auto"
                                    key={i}
                                >
                                    <div className="mb-4 text-xl font-bold text-gray-500">
                                        INFANT DETAILS
                                    </div>
                                    <div className="mb-6">
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Passenger Name:
                                            </div>
                                            <div>{item.name}</div>
                                        </div>
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Date of Birth:
                                            </div>
                                            <div>{item.dob}</div>
                                        </div>
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Passport:
                                            </div>
                                            <div>{item.passport}</div>
                                        </div>
                                        <div className="flex justify-between mb-2">
                                            <div className="font-bold">
                                                Remark:
                                            </div>
                                            <div className="long-text">
                                                {item.remark}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}

                <div className="">
                    <div className="greyOpaqueBackground px-8 py-6">
                        <div className="mb-4 text-3xl font-bold text-center ">
                            PRICING BREAKDOWN
                        </div>
                        <div className="mb-6">
                            <div className="mb-6">
                                {renderItem(
                                    "Adult Package",
                                    locate.state.payload.adultPax,
                                    locate.state.payload.breakdown.groundPackage
                                )}
                                {renderItem(
                                    "Child Package",
                                    locate.state.payload.childPax,
                                    locate.state.payload.breakdown
                                        .childGroundPackage
                                )}
                                {renderItem(
                                    "ChildNoBed Package",
                                    locate.state.payload.childnobedPax,
                                    locate.state.payload.breakdown
                                        .childNoBedGroundPackage
                                )}
                                {renderItem(
                                    "Single Package",
                                    locate.state.payload.singlePax,
                                    locate.state.payload.breakdown
                                        .singleGroundPackage
                                )}
                                {renderItem(
                                    "Infant Package",
                                    locate.state.payload.infantPax,
                                    locate.state.payload.infantPrice
                                )}
                            </div>
                            <div className="mb-6">
                                {renderItem(
                                    "Adult Supp",
                                    locate.state.payload.adultPax,
                                    locate.state.payload.breakdown.supp
                                )}

                                {renderItem(
                                    "Child Supp",
                                    locate.state.payload.childPax,
                                    locate.state.payload.breakdown.childSupp
                                )}

                                {renderItem(
                                    "Child No Bed Supp",
                                    locate.state.payload.childnobedPax,
                                    locate.state.payload.breakdown
                                        .childNoBedSupp
                                )}

                                {renderItem(
                                    "Single Supp",
                                    locate.state.payload.singlePax,
                                    locate.state.payload.breakdown.singleSupp
                                )}
                            </div>
                            <div className="mb-6">
                                {renderItem(
                                    "Adult Airfare",
                                    locate.state.payload.adultPax,
                                    locate.state.payload.breakdown.airticket
                                )}
                                {renderItem(
                                    "Child Airfare",
                                    locate.state.payload.childPax,
                                    locate.state.payload.breakdown
                                        .childAirticket
                                )}
                                {renderItem(
                                    "Child No Bed Airfare",
                                    locate.state.payload.childnobedPax,
                                    locate.state.payload.breakdown
                                        .childNoBedAirticket
                                )}
                                {renderItem(
                                    "Single Airfare",
                                    locate.state.payload.singlePax,
                                    locate.state.payload.breakdown
                                        .singleAirticket
                                )}
                            </div>

                            <div className="mb-6">
                                {renderItem(
                                    "Adult Tipping",
                                    locate.state.payload.adultPax,
                                    locate.state.payload.breakdown.tipping
                                )}
                                {renderItem(
                                    "Child Tipping",
                                    locate.state.payload.childPax,
                                    locate.state.payload.breakdown.childTipping
                                )}
                                {renderItem(
                                    "Child No Bed Tipping",
                                    locate.state.payload.childnobedPax,
                                    locate.state.payload.breakdown
                                        .childNoBedTipping
                                )}
                                {renderItem(
                                    "Single Tipping",
                                    locate.state.payload.singlePax,
                                    locate.state.payload.breakdown.singleTipping
                                )}
                            </div>

                            <div className="mb-6">
                                {renderItem(
                                    "Adult Insurance",
                                    locate.state.payload.adultPax,
                                    locate.state.payload.breakdown.insurance
                                )}
                                {renderItem(
                                    "Child Insurance",
                                    locate.state.payload.childPax,
                                    locate.state.payload.breakdown
                                        .childInsurance
                                )}
                                {renderItem(
                                    "Child No Bed Insurance",
                                    locate.state.payload.childnobedPax,
                                    locate.state.payload.breakdown
                                        .childNoBedInsurance
                                )}
                                {renderItem(
                                    "Single Insurance",
                                    locate.state.payload.singlePax,
                                    locate.state.payload.breakdown
                                        .singleInsurance
                                )}
                            </div>

                            <div className="mb-6">
                                {renderItem(
                                    "Adult Visa",
                                    locate.state.payload.adultPax,
                                    locate.state.payload.breakdown.visa
                                )}
                                {renderItem(
                                    "Child Visa",
                                    locate.state.payload.childPax,
                                    locate.state.payload.breakdown.childVisa
                                )}
                                {renderItem(
                                    "Child No Bed Visa",
                                    locate.state.payload.childnobedPax,
                                    locate.state.payload.breakdown
                                        .childNoBedVisa
                                )}
                                {renderItem(
                                    "Single Visa",
                                    locate.state.payload.singlePax,
                                    locate.state.payload.breakdown.singleVisa
                                )}
                            </div>

                            <div className="flex justify-between mb-2">
                                <div className="font-bold">Total Price:</div>
                                <div>
                                    RM{" "}
                                    {NumberFormatter.format(
                                        locate.state.payload.price
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="my-2 text-sm text-lynch font-medium">
                                Phone Number
                            </div>
                            <input
                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                    errors.phone
                                        ? "border-red-400"
                                        : "border-gray-300"
                                }`}
                                name="phone"
                                ref={register()}
                                type="string"
                                key="phone"
                            />
                            {errors.phone && (
                                <p className="text-red-400 text-xs">{`Phone cannot be empty`}</p>
                            )}
                        </div>
                        <div>
                            <div className="my-2 text-sm text-lynch font-medium">
                                Email
                            </div>
                            <input
                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                    errors.email
                                        ? "border-red-400"
                                        : "border-gray-300"
                                }`}
                                name="email"
                                ref={register()}
                                type="string"
                                key="email"
                            />
                            {errors.email && (
                                <p className="text-red-400 text-xs">{`Email cannot be empty`}</p>
                            )}
                        </div>
                        <div>
                            <div className="my-2 text-sm text-lynch font-medium">
                                Proof of Payment
                            </div>
                            <input
                                className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                    errors.image
                                        ? "border-red-400"
                                        : "border-gray-300"
                                }`}
                                name="image"
                                ref={register()}
                                type="file"
                                key="image"
                            />
                            {errors.image && (
                                <p className="text-red-400 text-xs">{`Image cannot be empty`}</p>
                            )}
                        </div>
                        <div className="text-center">
                            <button
                                className="hover:bg-iconblue bg-yellow-400 text-black hover:text-white font-bold py-2 px-4 rounded my-4"
                                onClick={handleSubmit(handleCreate)}
                            >
                                Submit Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Checkout;
