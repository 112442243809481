import React, { useEffect } from "react";
import queries from "../helpers/queries";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { withUser } from "../Contexts/user";

const formFields = [
    {
        name: "name",
        label: "Full Name",
    },
    {
        name: "email",
        label: "Email",
    },
    {
        name: "phone",
        label: "Phone Number",
    },
];


const EditProfile = ({ user }) => {
    const modalData = user.auth;
    const { register, handleSubmit, errors, getValues, setValue } = useForm({
        defaultValues: {
            name: "",
            email: "",
            phone: "",
        },
    });

    const history = useHistory();

    useEffect(() => {
        const { name, email, phone } = modalData;

        const values = [{ name }, { email }, { phone }];
        setValue(values);
    }, [modalData, setValue]);

    const [editUserProfile] = useMutation(queries.EDIT_USER_PROFILE);

    const handleEditUserProfile = async () => {
        try {
            const formValues = getValues();

            const result = await editUserProfile({
                variables: {
                    name: formValues.name,
                    email: formValues.email,
                    phone: formValues.phone,
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                toast("Your Profile has successfully been updated");
                history.push("/");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    return (
        <div
            className="flex h-screen lg:h-full p-8"
            style={{ backgroundColor: "black" }}
        >
            <div className="container h-full mx-auto flex justify-center items-center">
                <div className="panel bg-white p-8 lg:p-16 rounded-md">
                    <div className="flex justify-end">
                        <Link to="/">
                            <FiX className="cursor-pointer" />
                        </Link>
                    </div>
                    <div className="mb-6 text-3xl font-bold">Edit Profile</div>
                    <form
                        onSubmit={handleSubmit(handleEditUserProfile)}
                        className="lg:w-104"
                    >
                        {formFields.map((item, i) => {
                            return (
                                <div key={item.name}>
                                    <div className="mb-2 text-sm text-lynch font-medium capitalize">
                                        {item.label}
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full capitalize rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            errors[item.name]
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name={item.name}
                                        ref={register({ required: false })}
                                        placeholder={item.label}
                                        type={item.type ? item.type : "text"}
                                    />
                                    {errors[item.name] && (
                                        <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                    )}
                                </div>
                            );
                        })}
                        <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withUser(EditProfile);
