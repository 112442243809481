export const sliderItems = [

    {
        id: 1,
        img: "https://ik.imagekit.io/dnddecpho/longrich/%E5%BC%A0%E5%AE%B6%E7%95%8C%20-%20june%2024%20-%201920%20x%20800%20-%20high%20res_YQlXunfZ9.jpg?updatedAt=1714925404256",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/longrich/%E5%BC%A0%E5%AE%B6%E7%95%8C%20-%20june%2024%202%20-%20low%20res_9MLOCt9fM.jpg?updatedAt=1714926184532",
        url: "/location",
    },
    {
        id: 2,
        img: "https://ik.imagekit.io/dnddecpho/longrich/%E5%BC%A0%E5%AE%B6%E7%95%8C%20-%20july%20-%201920%20x%20800%20-%20low%20res_qKUdumpHT.jpg?updatedAt=1714925375152",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/longrich/%E5%BC%A0%E5%AE%B6%E7%95%8C%20-%20july%2024%20-%201080%20x%20800%20-%20high%20res_-nKxfB2lr.jpg?updatedAt=1714926184670",
            url: "/location",
    },
    {
        id: 3,
        img: "https://ik.imagekit.io/dnddecpho/longrich/%E5%BC%A0%E5%AE%B6%E7%95%8C%20-%20sept%2024%20-%201920%20x%20800%20-%20high%20res_9AKJS86_F.jpg?updatedAt=1714925404378",
        mobileImg:
            "https://ik.imagekit.io/dnddecpho/longrich/%E5%BC%A0%E5%AE%B6%E7%95%8C%20-%20sept%2024%20-%201080%20x%20800%20-%20high%20res_Gi5GWP4IC.jpg?updatedAt=1714926184596",
        url: "/",
    },
    
    

];
