import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    backlist: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    addBackList: (state, action) => {
        state.isFetching = false;
        action.payload !== "" ? state.backlist.push(action.payload) : state.backlist = []
    },
    removeBackList: (state) => {
        state.isFetching = false;
        state.backlist.pop();
    },
  },
});

export const { addBackList, removeBackList } = userSlice.actions;
export default userSlice.reducer;
