import React, { useState, createContext, useEffect, useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import queries from "../helpers/queries";

export const UserContext = createContext({
  auth: {
    token: null,
    name: null,
    userId: null,
    type: null,
    loaded: null,
    ic: null,
    customRank: null,
    image: null,
    cp58: null,
    login: () => {},
    logout: () => {},
    fetchUser: () => {},
  },
});

export const UserProvider = ({ children }) => {
  const apollo = useApolloClient();

  const login = (authData, rememberMe) => {
    setAuth({
      ...auth,
      ...authData,
      loaded: true,
    });

    if (rememberMe) {
      localStorage.setItem("auth", JSON.stringify({ ...authData }));
    } else {
      sessionStorage.setItem("auth", JSON.stringify({ ...authData }));
    }
  };

  const logout = () => {
    setAuth({
      ...auth,
      token: null,
      name: null,
      userId: null,
      type: null,
      loaded: true,
      ic: null,
      customRank: null,
      image: null,
      cp58: null,
    });

    sessionStorage.clear();
    localStorage.clear();
  };

  const fetchUser = useCallback(async () => {
    const existingItem =
      JSON.parse(sessionStorage.getItem("auth")) ||
      JSON.parse(localStorage.getItem("auth"));

    if (existingItem) {
      (async () => {
        const payload = await apollo.query({
          query: queries.WHOAMI,
        });

        setAuth((auth) => ({
          ...auth,
          ...existingItem,
          ...payload.data.whoami,
          loaded: true,
        }));
      })();
    } else {
      setAuth((auth) => ({
        ...auth,
        loaded: true,
      }));
    }
  }, [apollo]);

  const [auth, setAuth] = useState({
    token: null,
    name: null,
    userId: null,
    type: null,
    loaded: false,
    ic: null,
    customRank: null,
    image: null,
    cp58: null,
    login,
    logout,
    fetchUser,
  });

  useEffect(() => {
    fetchUser();
  }, [apollo, fetchUser]);

  return (
    <UserContext.Provider value={{ auth }}>{children}</UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;

export const withUser = (Component) => (props) => {
  return (
    <UserConsumer>
      {(user) => <Component {...props} user={user} />}
    </UserConsumer>
  );
};
