import React from "react";
import queries from "../helpers/queries";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
  });

  const history = useHistory();

  const [changePassword] = useMutation(queries.CHANGE_PASSWORD);

  const handleChangePassword = async ({
    currentPassword,
    password,
    confirmPassword,
  }) => {
    if (password !== confirmPassword) {
      toast("Passwords don't match");

      return;
    }

    try {
      const result = await changePassword({
        variables: {
          currentPassword,
          password,
        },
      });

      if (result.error) {
        alert("Failed");
      }

      if (result.data) {
        toast("Your Password has successfully been changed");
        history.push("/");
      }
    } catch (err) {
      alert("Failed");
      console.log(err);
    }
  };
  return (
    <div
      className="flex h-screen lg:h-full p-8"
      style={{ backgroundColor: "black" }}
    >
      <div className="container h-full mx-auto flex justify-center items-center">
        <div className="panel bg-white lg:p-16 p-8 rounded-md">
          <div className="flex justify-end">
            <Link to="/">
              <FiX className="cursor-pointer" />
            </Link>
          </div>
          <div className="mb-6 lg:mb-8 text-3xl font-bold">Change Password</div>
          <form
            onSubmit={handleSubmit(handleChangePassword)}
            className="lg:w-104"
          >
            <div className="">Current Password</div>
            <input
              className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                errors.currentPassword ? "border-red-400" : "border-gray-300"
              }`}
              name="currentPassword"
              ref={register({ required: true })}
              placeholder="Current Password"
              type="password"
            />
            {errors.currentPassword && (
              <p className="text-red-400 text-xs">{`Password cannot be empty`}</p>
            )}
            <div className="">New Password</div>
            <input
              className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                errors.password ? "border-red-400" : "border-gray-300"
              }`}
              name="password"
              ref={register({ required: true })}
              placeholder="Password"
              type="password"
            />
            {errors.password && (
              <p className="text-red-400 text-xs">{`Password cannot be empty`}</p>
            )}
            <div className="">Confirm Password</div>
            <input
              className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                errors.confirmPassword ? "border-red-400" : "border-gray-300"
              }`}
              name="confirmPassword"
              ref={register({ required: true })}
              placeholder="Confirm Password"
              type="password"
            />
            {errors.confirmPassword && (
              <p className="text-red-400 text-xs">{`Confirm Password cannot be empty`}</p>
            )}
            <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
