import React from "react";
import { Link } from "react-router-dom";
import {
    FcPhoneAndroid,
    FcCapacitor,
    FcServices,
    FcVip,
    FcCopyright,
    FcLibrary,
    FcGlobe,
} from "react-icons/fc";
import { withUser } from "../Contexts/user";

const Dashboard = ({ user }) => {
    return (
        <div className="flex h-full p-8 bg-black">
            <div className="border border-iconblue flex flex-wrap bg-cool-gray rounded-md w-full overflow-hidden">
               
                <div className="w-full lg:w-2/3 p-8">
                    <div className="text-black text-3xl capitalize text-left lg:text-left">
                        {user ? user.auth.nickname : ""}
                    </div>
                    <div className="text-lynch text-xl capitalize text-left lg:text-left">
                        {user ? user.auth.name : ""}
                    </div>
                    <div className="flex items-center my-2 capitalize justify-start lg:justify-start">
                        <FcVip className="mr-2" />
                        {user.auth.ranking
                            ? user.auth.customRank
                                ? user.auth.customRank
                                : user.auth.ranking.name
                            : ""}
                    </div>
                    <div className="grid grid-cols-1 col-gap-4 row-gap-2 lg:flex">
                        <Link to="/editprofile">
                            <button className="bg-specialpink hover:bg-iconpink hover:text-white py-2 px-3 lg:mr-1 rounded w-full">
                                Edit My Profile
                            </button>
                        </Link>
                        <Link to="/changepassword">
                            <button className="bg-specialgreen hover:bg-icongreen hover:text-white py-2 px-3 rounded w-full">
                                Change Password
                            </button>
                        </Link>
                        <a
                            className={`${
                                user.auth.cp58 !== null && user.auth.cp58 !== ""
                                    ? ""
                                    : "hidden"
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={user.auth.cp58}
                        >
                            <button className="bg-specialblue hover:bg-iconblue hover:text-white py-2 px-3 rounded w-full">
                                Download CP58
                            </button>
                        </a>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 row-gap-4 my-8">
                        <div className="border-b">
                            <div className="py-4">
                                <div className="flex items-center text-lynch">
                                    <FcCapacitor className="mr-2" />
                                    <div className="font-bold">Email Address</div>
                                </div>
                                <div className="text-lynch">
                                    {user ? user.auth.email : ""}
                                </div>
                            </div>
                        </div>
                        <div className="border-b">
                            <div className="py-4">
                                <div className="flex items-center text-lynch">
                                    <FcPhoneAndroid className="mr-2" />
                                    <div className="font-bold">
                                        Phone Number
                                    </div>
                                </div>
                                <div className="text-lynch">
                                    {user ? user.auth.phone : ""}
                                </div>
                            </div>
                        </div>
                        <div className="border-b">
                            <div className="py-4">
                                <div className="flex items-center text-lynch">
                                    <FcServices className="mr-2" />
                                    <div className="font-bold">Type</div>
                                </div>
                                <div className="text-lynch">
                                    {user ? user.auth.type : ""}
                                </div>
                            </div>
                        </div>
                        <div className="border-b">
                            <div className="py-4">
                                <div className="flex items-center text-lynch">
                                    <FcCopyright className="mr-2" />
                                    <div className="font-bold">
                                        Bank Account
                                    </div>
                                </div>
                                <div className="text-lynch">Public Bank</div>
                            </div>
                        </div>
                        <div className="border-b">
                            <div className="py-4">
                                <div className="flex text-lynch items-center">
                                    <FcLibrary className="mr-2" />
                                    <div className="font-bold">
                                        Bank Account Number
                                    </div>
                                </div>
                                <div className="text-lynch">
                                    {user ? user.auth.bankDetails : ""}
                                </div>
                            </div>
                        </div>
                        <div className="border-b">
                            <div className="py-4">
                                <div className="flex items-center text-lynch">
                                    <FcGlobe className="mr-2" />
                                    <div className="font-bold">Country</div>
                                </div>
                                <div className="text-lynch">Malaysia</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withUser(Dashboard);
