import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRef, useEffect } from "react";
import { LocationOn, Mail, Phone } from "@material-ui/icons";
import BannerOverlay from "../Components/BannerOverlay";

const Container = styled.div`
    width: 100vw;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("https://ik.imagekit.io/dnddecpho/misc/nice_5YGNFp95C.png?updatedAt=1695903213302")
            center;
    background-size: cover;
    display: grid;
    grid-template-columns: 50% 50%;
    ${mobile({ display: "block", height: "100%" })}
    ${tablet({ display: "block", height: "100%" })}
`;

const Title = styled.h1`
    font-size: 2rem;
    color: white;
    display: flex;
    align-items: center;
    font-family: FuturaPT-Bold;
        ${mobile({ fontSize: "1.5rem", paddingBottom: "1rem" })}
          ${tablet({ fontSize: "1.8rem", paddingBottom: "1rem" })}
`;

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    ${mobile({ display: "flex", flexDirection: "column" })}
    ${tablet({ display: "flex", flexDirection: "column" })}
`;

const Input = styled.input`
    font-family: FuturaPT-Demi;
    flex: 1;
    min-width: 40%;
    margin: 20px 10px 0px 0px;
    padding: 14px;
`;

const PhoneInput = styled.input`
    font-family: FuturaPT-Demi;
    flex: 1;
    min-width: 90%;
    margin: 20px 10px 0px 0px;
    padding: 14px;
`;

const MessageInput = styled.input`
    font-family: FuturaPT-Demi;
    flex: 1;
    min-width: 90%;
    margin: 20px 10px 0px 0px;
    padding: 30px 14px;

    &:after {
        content: " 🦄";
    }
`;
const Button = styled.button`
    width: 40%;
    border: 1px black solid;
    padding: 15px 20px;
    margin-top: 15px;
    margin-bottom: 1rem;
    background-color: black;
    color: white;
    cursor: pointer;
    font-family: FuturaPT-Bold;
    font-size: 1.2rem;
    &:hover {
        background: #ead61c;
        color: black;
    }
    ${mobile({ width: "70vw" })}
    ${tablet({ width: "70vw" })}
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 5rem;
    padding: 3rem;
    ${mobile({
        width: "90%",
        margin: "0rem",
        height: "100%",
        padding: "3rem 1rem",
    })}
    ${tablet({
        width: "90%",
        margin: "0rem",
        height: "100%",
        padding: "3rem 1rem",
    })}
`;

const Desc = styled.h3`
    color: white;
    font-family: FuturaPT-Demi;
    opacity: 0.75;
    font-size: 1.5rem;
    font-weight: 300;
    ${mobile({ padding: "0", fontSize: "1rem" })}
    ${tablet({ padding: "0", fontSize: "1.2rem"  })}
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.8rem 3rem;
    ${mobile({ padding: "1rem 0" })}
    ${tablet({ padding: "1rem 0" })}
`;


const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const form = useRef();
    const handleOnSubmit = (e) => {
        e.preventDefault();
        e.target.reset();
    };

    return (
        <>
            <BannerOverlay
                imageUrl="https://ik.imagekit.io/dnddecpho/dndream/banner-contact_c0YhblrHc.jpg?updatedAt=1706109380206"
                title="Contact"
            ></BannerOverlay>
            <Container>
                <Wrapper>
                    <InfoContainer>
                        <Title>
                            <LocationOn
                                style={{
                                    marginRight: "1rem",
                                }}
                            ></LocationOn>{" "}
                            Address
                        </Title>
                        <Desc>
                        LONGRICH TOUR & TRAVEL SDN BHD<br></br> B-1-02, Residensi The Herz, Jalan 3/33a, Kepong Baru, 52100 Kuala Lumpur
                        </Desc>
                    </InfoContainer>
                    <InfoContainer>
                        <Title>
                            <Mail
                                style={{
                                    marginRight: "1rem",
                                }}
                            ></Mail>{" "}
                            Email
                        </Title>
                        <Desc>longrichtour@gmail.com</Desc>
                    </InfoContainer>
                    <InfoContainer>
                        <Title>
                            <Phone
                                style={{
                                    marginRight: "1rem",
                                }}
                            ></Phone>{" "}
                            Contact
                        </Title>
                        <Desc>016 622 3451</Desc>
                    </InfoContainer>
                </Wrapper>
                <Wrapper>
                    <Title>Got a question?</Title>
                    <Desc>We would love to talk about how we can help</Desc>
                    <Form ref={form} onSubmit={handleOnSubmit}>
                        <Input
                            id="grid-first-name"
                            placeholder="First Name"
                            name="firstName"
                        />
                        <Input
                            id="grid-last-name"
                            placeholder="Last Name"
                            name="lastName"
                        />
                        <PhoneInput
                            id="grid-number"
                            placeholder="Phone Number"
                            name="phoneNumber"
                        />
                        <MessageInput
                            id="grid-message"
                            placeholder="Enter your message here"
                            name="message"
                        />
                        <Button>SEND</Button>
                        <ToastContainer
                            style={{ width: "fit-content", overflow: "hidden" }}
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </Form>
                </Wrapper>
            </Container>
        </>
    );
};

export default Contact;
